// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import dealIconBackground from "assets/img/deal.svg"
// page images
import networkingIconBackground from "assets/img/networking.svg"
// page styles
import sponsorStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/sponsorStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
// react libraries
import Fade from "react-reveal/Fade"

const useStyles = makeStyles(sponsorStyle)

export default function SectionFeatures() {
  const {
    albrechtFuneralHomesLogo,
    americanFamilyInsuranceLogo,
    anchorLogo,
    ascendiumLogo,
    badgerVipLimoLogo,
    bandtCommunicationsLogo,
    blueChipsLogo,
    buschLightLogo,
    fiftyNinerLogo,
    gflLogo,
    homeBackupSystemsLogo,
    ikiManufacturingLogo,
    ironCountryLogo,
    jBeckerConstructionLogo,
    lexusMadisonLogo,
    minutemanPressLogo,
    mkApparelLogo,
    morrisonsAutoSalvageLogo,
    nelnetLogo,
    nelsonsRecLogo,
    reitzDentalLogo,
    ssmHealthLogo,
    theHogLogo,
    timberHillLogo,
    twoBrothersLogo,
    wisconsinGreenhouseLogo,
  } = useStaticQuery(graphql`
    query getSponsorLogos {
      albrechtFuneralHomesLogo: file(
        relativePath: { eq: "logos/albrecht-funeral-homes-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      americanFamilyInsuranceLogo: file(
        relativePath: { eq: "logos/american-family-insurance-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      anchorLogo: file(relativePath: { eq: "logos/anchor-inn-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      ascendiumLogo: file(relativePath: { eq: "logos/ascendium-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      badgerVipLimoLogo: file(
        relativePath: { eq: "logos/badger-vip-limo-logo.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      bandtCommunicationsLogo: file(
        relativePath: { eq: "logos/bandt-communications-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      blueChipsLogo: file(relativePath: { eq: "logos/blue-farm-logo.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      buschLightLogo: file(relativePath: { eq: "logos/busch-light-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      fiftyNinerLogo: file(relativePath: { eq: "logos/59er-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      gflLogo: file(relativePath: { eq: "logos/gfl-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      homeBackupSystemsLogo: file(
        relativePath: { eq: "logos/home-backup-systems-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      ikiManufacturingLogo: file(relativePath: { eq: "logos/iki-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      ironCountryLogo: file(
        relativePath: { eq: "logos/1019-iron-country-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      jBeckerConstructionLogo: file(
        relativePath: { eq: "logos/j-becker-construction-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      lexusMadisonLogo: file(
        relativePath: { eq: "logos/lexus-madison-logo.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      minutemanPressLogo: file(
        relativePath: { eq: "logos/minuteman-press-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      mkApparelLogo: file(relativePath: { eq: "logos/mk-apparel-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      morrisonsAutoSalvageLogo: file(
        relativePath: { eq: "logos/morrisons-auto-salvage-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      nelnetLogo: file(relativePath: { eq: "logos/nelnet-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      nelsonsRecLogo: file(
        relativePath: { eq: "logos/nelsons-hometowne-rec-logo.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      reitzDentalLogo: file(
        relativePath: { eq: "logos/reitz-dental-chilimania.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      ssmHealthLogo: file(relativePath: { eq: "logos/ssm-health-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      theHogLogo: file(relativePath: { eq: "logos/1059-the-hog-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      timberHillLogo: file(
        relativePath: { eq: "logos/timber-hill-winery-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      twoBrothersLogo: file(relativePath: { eq: "logos/2brothers-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      wisconsinGreenhouseLogo: file(
        relativePath: {
          eq: "logos/wisconsin-greenhouse-company-logo-black.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <img
          className={classes.networkingLogo}
          src={networkingIconBackground}
          alt="Networking Icon"
        />
        <GridContainer
          justifyContent="center"
          alignItems="center"
          className={classes.sponsorsTitleContainer}
        >
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.title}>
              Thank You to our Presenting Sponsors
            </h2>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} className={classes.sponsorsTitleItem}>
            <h3 className={classes.reaperSponsorTitle}>Friday VIP Sponsor</h3>
            <Fade delay={100}>
              <GatsbyImage
                image={homeBackupSystemsLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Home Backup Systems Logo"
                title="Home Backup Systems"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} className={classes.sponsorsTitleItem}>
            <h3 className={classes.reaperSponsorTitle}>Saturday VIP Sponsor</h3>
            <Fade delay={200}>
              <GatsbyImage
                image={morrisonsAutoSalvageLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Morrison's Auto Salvage, Inc Logo"
                title="Morrison's Auto Salvage, Inc"
              />
            </Fade>
          </GridItem>
        </GridContainer>
        <GridContainer
          className={classes.sponsorsGridContainer}
          alignItems="center"
          justifyContent="center"
        >
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.reaperSponsorTitle}>
              Carolina Reaper Sponsors
            </h3>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={200}>
              <GatsbyImage
                image={twoBrothersLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="2Brothers Bar &amp; Grill Logo"
                title="2Brothers Bar &amp; Grill"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={300}>
              <GatsbyImage
                image={fiftyNinerLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Fifty Niner Logo"
                title="59er"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={400}>
              <GatsbyImage
                image={ascendiumLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Ascendium Education Group Logo"
                title="Ascendium Education Group"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={500}>
              <GatsbyImage
                image={
                  americanFamilyInsuranceLogo.childImageSharp.gatsbyImageData
                }
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="American Family Insurance Logo"
                title="American Family Insurance"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={600}>
              <GatsbyImage
                image={anchorLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Anchor Bar &amp; Grill Logo"
                title="Anchor Bar &amp; Grill"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={700}>
              <GatsbyImage
                image={bandtCommunicationsLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Bandt Communications Logo"
                title="Bandt Communications"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={800}>
              <GatsbyImage
                image={buschLightLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Wisconsin Distributors Busch Light Logo"
                title="Wisconsin Distributors"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={900}>
              <GatsbyImage
                image={homeBackupSystemsLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Home Backup Systems Logo"
                title="Home Backup Systems"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={1000}>
              <GatsbyImage
                image={minutemanPressLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Minuteman Press Logo"
                title="Minuteman Press"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={1100}>
              <GatsbyImage
                image={morrisonsAutoSalvageLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Morrison's Auto Salvage, Inc Logo"
                title="Morrison's Auto Salvage, Inc"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={1200}>
              <GatsbyImage
                image={reitzDentalLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Reitz and Gregerson Dental Logo"
                title="Reitz and Gregerson Dental"
              />
            </Fade>
          </GridItem>
        </GridContainer>
        <img
          className={classes.dealLogo}
          src={dealIconBackground}
          alt="Deal Icon"
        />
        <GridContainer
          className={classes.sponsorsGridContainer}
          alignItems="center"
          justifyContent="center"
        >
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.ghostSponsorTitle}>Ghost Pepper Sponsors</h3>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={200}>
              <GatsbyImage
                image={albrechtFuneralHomesLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Albrecht Funeral Home Logo"
                title="Albrecht Funeral Home"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={300}>
              <GatsbyImage
                image={badgerVipLimoLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Badger VIP Limousine Logo"
                title="Badger VIP Limousine"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={400}>
              <GatsbyImage
                image={theHogLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="105.9 The Hog Logo"
                title="105.9 The Hog"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={500}>
              <GatsbyImage
                image={ironCountryLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Iron Country 101.9 Logo"
                title="Iron Country 101.9"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={600}>
              <GatsbyImage
                image={blueChipsLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Blue Farm Chips Logo"
                title="Blue Farm Chips"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={700}>
              <GatsbyImage
                image={ikiManufacturingLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="IKI Manufacturing Logo"
                title="IKI Manufacturing"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={800}>
              <b>Jessica Osbourne</b>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={900}>
              <b>Josh &amp; Olivia Long</b>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={1000}>
              <GatsbyImage
                image={mkApparelLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="MK Apparel Logo"
                title="MK Apparel"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={1100}>
              <GatsbyImage
                image={nelsonsRecLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Nelson's Hometowne Recreation Logo"
                title="Nelson's Hometowne Recreation"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={1200}>
              <GatsbyImage
                image={wisconsinGreenhouseLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Wisconsin Greenhouse Company Logo"
                title="Wisconsin Greenhouse Company"
              />
            </Fade>
          </GridItem>
        </GridContainer>
        <GridContainer
          className={classes.sponsorsGridContainer}
          alignItems="center"
          justifyContent="center"
        >
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.ghostSponsorTitle}>Habanero Sponsors</h3>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={200}>
              <GatsbyImage
                image={gflLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="GFL Green for Life Logo"
                title="GFL Green for Life"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={300}>
              <GatsbyImage
                image={jBeckerConstructionLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="J. Becker Construction, Inc. Logo"
                title="J. Becker Construction, Inc."
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={400}>
              <b>Jacki &amp; Don Fairbairn</b>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={500}>
              <GatsbyImage
                image={lexusMadisonLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Lexus of Madison Logo"
                title="Lexus of Madison"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={600}>
              <GatsbyImage
                image={nelnetLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Nelnet Logo"
                title="Nelnet"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={700}>
              <GatsbyImage
                image={ssmHealthLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="SSM Health Logo"
                title="SSM Health"
              />
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={3} className={classes.sponsorsGridItem}>
            <Fade delay={800}>
              <GatsbyImage
                image={timberHillLogo.childImageSharp.gatsbyImageData}
                className={classes.gridLogo}
                imgStyle={{ objectFit: "contain" }}
                alt="Timber Hill Winery Logo"
                title="Timber Hill Winery"
              />
            </Fade>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} md={12} className={classes.sponsorButton}>
            <Fade delay={400}>
              <Link to="/sponsor-list">
                <Button color="primary" size="lg">
                  See All Sponsors
                </Button>
              </Link>
            </Fade>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
