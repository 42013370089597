import {
  container,
  description,
  mlAuto,
  mrAuto,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const sponsorStyle = (theme) => ({
  container: {
    ...container,
    ...textCenter,
  },
  dealLogo: {
    position: "absolute",
    width: "45%",
    opacity: ".25",
    bottom: "0%",
    right: "10%",
    [theme.breakpoints.down("xs")]: {
      right: "4%",
      bottom: "4%",
      opacity: ".15",
    },
  },
  description,
  ghostSponsorTitle: {
    ...title,
    ...textCenter,
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
      marginBottom: "0px",
    },
  },
  gridFeaturedLogo: {
    maxWidth: "30%",
    margin: "0 auto",
    zIndex: "1",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "75%",
    },
  },
  gridLogo: {
    width: "75%",
    margin: "30px auto",
  },
  networkingLogo: {
    position: "absolute",
    width: "30%",
    opacity: ".4",
    top: "10%",
    left: "15%",
    [theme.breakpoints.down("xs")]: {
      top: "7%",
      left: "4%",
      opacity: ".2",
    },
  },
  reaperSponsorTitle: {
    ...title,
    marginBottom: "30px",
  },
  section: {
    padding: "80px 0",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "& $title": {
      marginBottom: "30px",
    },
    "& $title,& $sponsorGridContainer": {
      position: "relative",
      zIndex: "2",
    },
    // "& $sponsorGridBottom": {
    //   "& $sponsorGridItem": {
    //     borderBottom: "0",
    //   },
    // },
    // "& $sponsorGridItem": {
    //   border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
    //   borderTop: "0",
    //   borderLeft: "0",
    //   "&:last-child": {
    //     borderRight: "0",
    //   },
    //   [theme.breakpoints.down("xs")]: {
    //     borderBottom: "0px",
    //   },
    // },
  },
  sponsorButton: {
    margin: "3rem 1rem 30px 1rem",
    padding: "1.25rem 2.25rem",
    fontSize: "1rem",
    border: "0 !important",
    ...textCenter,
  },
  sponsorGridBottom: {},
  sponsorGridContainer: {},
  sponsorGridItem: {},
  sponsorTitleContainer: {
    ...textCenter,
  },
  sponsorTitleItem: {
    ...mlAuto,
    ...mrAuto,
    ...textCenter,
    marginBottom: "30px",
  },
  title,
})

export default sponsorStyle
